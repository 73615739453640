import { ref, computed } from 'vue'

const visible = ref(false)

const set = (value) => {
  visible.value = value
}

const reallyVisible = ref(false)

watchEffect(() => {
  if (visible.value) {
    reallyVisible.value = true
  } else {
    setTimeout(() => {
      reallyVisible.value = false
    }, 600)
  }
})

const bodyAttrs = computed(() => {
  return reallyVisible.value ? 'blocked' : ''
})



let subscribedClose = []

const subscribeClose = (cb) => {
  subscribedClose.push(cb)
}

const closeSubscribed = () => {
  subscribedClose.forEach(cb => cb())
  subscribedClose = []
}

export function useOverlay () {

  useHead(() => ({
    bodyAttrs: {
      class: bodyAttrs
    },
  }))

  return {
    visible,
    reallyVisible,
    set,
    subscribeClose,
    closeSubscribed
  }
}